import { lazy } from 'react'

// 一级路由
let Home = lazy(() => import('../page/home'))

//二级路由
let Index = lazy(() => import('../page/index/index'))
let Detail = lazy(() => import('../page/index/details'))
let news = lazy(() => import('../page/index/news'))
let Zool = lazy(() => import('../page/index/zoology'))
let Turbo = lazy(()=>import('../page/index/turbo')) 
let Everisland = lazy(()=>import('../page/index/everisland'))

// 创建路由表
let routeList = [
    {
        path: '/seelen',
        component: Home,
        children: [
            {
                path: '/seelen/index',
                component: Index
            },
            {
                path: '/seelen/details/:id',
                component: Detail
            },
            {
                path: '/seelen/news',
                component: Zool
            },
            {
                path: '/seelen/ecology',
                component: news
            },
            {
                path:'/seelen/turbo',
                component:Turbo
            },
            
            {
                path:'/seelen/everisland',
                component:Everisland
            },
            {
                path:'/seelen/zhuanti',
                component: lazy(()=>import('../page/index/zhuanti'))
            }
        ]
    },
    {
        from: '/',
        to: '/seelen/index'
    }
]



// 抛出路由表

export default routeList