import { HashRouter as Router } from 'react-router-dom'
// import { BrowserRouter as Router  } from "react-router-dom";

import routeList from './router/RouterList'

import RouterView from './router/RouterView'

function App() {
  return (
    <Router>
      <RouterView routeList={routeList}/>
    </Router>
  );
}

export default App;