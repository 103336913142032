import { FC } from 'react'
import Load from '../component/Load'

import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'

import { Suspense } from 'react'

interface routeList{
    routeList:Array<routeListItem>,
    navlist?:Array<routeListItem>
}

interface routeListItem{
    path?:string,
    component?:any,
    name?:string,
    to?:string,
    from?:string,
    children?:Array<routeListItem>,
    isRequired?:boolean
}

const RouterView:FC<routeList>=({routeList})=>{


    // 获取路由
    let list = routeList.filter(item=>!item.to)

    // 获取重定向
    let redirect = routeList.filter(item=>item.to)[0]

    return (
        <Suspense fallback={<Load/>}>
            <Switch>
                {
                    list&&list.length>0?list.map((item,index)=>{
                        return <Route
                        path={item.path}
                        key={index}
                        render={(routerProps:routeListItem)=>{
                            let Com = item.component

                            if(item.children){
                                return <Com
                                {...routerProps}
                                routeList={item.children}
                                navlist={item.children.filter(item=>!item.to)}
                                />
                            }
                            // console.log(item.isReuired);
                            
                            if(item.isRequired&&!localStorage.getItem('user')){
                                return <Redirect to={'/login'} from={item.path}/>
                            }

                            return <Com {...routerProps}/>
                        }}
                        
                        ></Route>
                    }):<p>暂无数据</p>
                },
                {
                    redirect&&<Redirect to={redirect.to as string} from ={redirect.from}/>
                }
            </Switch>
        </Suspense>
    )
}

export default RouterView