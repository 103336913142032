import React from 'react'

// 引入加载图片
import Seele2 from '../assets/image1/seele2.png'
import Seele from '../assets/image1/seele.png'

import '../assets/css/load/load.css'

const Load = () => {
    return (
        <div className='load'>
            <div className="loadimg">
                <img src={Seele} alt="" />
                <img src={Seele2} alt="" />
            </div>
        </div>
    )
}
export default Load